.footer-links a{
    color: rgb(32, 32, 32);
    text-decoration: none;
    margin-top: 0.2rem;
    font-weight: 500;
}
.footer-links a:hover{
    color: #00b4eb;
}
.footer{
    /* background-color: rgba(145, 229, 252, 0.189); */
    background-color: #00c8ff21;
    height: auto;
}