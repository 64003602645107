#grad {
    background-image: linear-gradient(#00c8ff21,white,#00c8ff21,white);
    height: auto;
    padding-top: 4rem;
    width: auto;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}

.button-class{
    background-color: #00b4eb;
}
.button-class:hover{
    background-color: #03b9f1d3;
    color: white;
}
.parallax{
    background-image:url("../images/snap3.png");
    height: 100%;
    width:100%;
    padding:0px;
    margin:0px;
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size:cover;
}
.benifits{
    background-color: #00000046;
    height: auto;
    padding-top: 2rem ;
    padding-bottom: 2rem ;
}
.list {
    list-style:square;
}
.list li{
    color: white;
    font-size: 1.3rem;
   
}
.ulwhy-savvy li{
    text-align: left;
}

.ulwhy-savvy{
    list-style: none;
}
