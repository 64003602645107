h1,
h2,
h3,
h4,
h5,
h6 {}
a,
a:hover,
a:focus,
a:active {
    text-decoration: none;
    outline: none;
}

a,
a:active,
a:focus {
    color: #333;
    text-decoration: none;
    transition-timing-function: ease-in-out;
    -ms-transition-timing-function: ease-in-out;
    -moz-transition-timing-function: ease-in-out;
    -webkit-transition-timing-function: ease-in-out;
    -o-transition-timing-function: ease-in-out;
    transition-duration: .2s;
    -ms-transition-duration: .2s;
    -moz-transition-duration: .2s;
    -webkit-transition-duration: .2s;
    -o-transition-duration: .2s;
}

ul {
    margin: 0;
    padding: 0;
    list-style: none;
}
img {
max-width: 100%;
height: auto;
}
span, a, a:hover {
display: inline-block;
text-decoration: none;
color: inherit;
}
.section-head {
margin-bottom: 60px;
}
.section-head h4 {
position: relative;
padding:0;
color:#00b4eb;
line-height: 1;
letter-spacing:0.3px;
font-size: 34px;
font-weight: 700;  
text-align:center;
text-transform:none;
margin-bottom:30px;
}
.section-head h4:before {
content: '';
width: 60px;
height: 3px;
background: #00b4eb;
position: absolute;
left: 0px;
bottom: -10px;
right:0;  
margin:0 auto;
}
.section-head h4 span {
font-weight: 700;
padding-bottom: 5px;
color:#2f2f2f
}
p.service_text{
color:#cccccc !important;
font-size:16px;
line-height:28px;
text-align:center;    
}
.section-head p, p.awesome_line{
color:#818181;
font-size:16px;
line-height:28px;
text-align:center;  
}

.extra-text {
font-size:34px;
font-weight: 700;
color:#2f2f2f;
margin-bottom: 25px;
position:relative;
text-transform: none;
}
.extra-text::before {
content: '';
width: 60px;
height: 3px;
background: #00b4eb;
position: absolute;
left: 0px;
bottom: -10px;
right: 0;
margin: 0 auto;
}
.extra-text span {
font-weight: 700;
color:#00b4eb;
}
.item {
background: #fff;
text-align: center;
padding: 30px 25px;
-webkit-box-shadow:0 0px 25px rgba(0, 0, 0, 0.07);
box-shadow:0 0px 25px rgba(0, 0, 0, 0.07);
border-radius: 20px;
border:5px solid rgba(0, 0, 0, 0.07);
margin-bottom: 30px;
-webkit-transition: all .5s ease 0;
transition: all .5s ease 0;
transition: all 0.5s ease 0s;
}
.item:hover{
background:#00c3ffd5;
box-shadow:0 8px 20px 0px rgba(0, 0, 0, 0.2);
-webkit-transition: all .5s ease 0;
transition: all .5s ease 0;
transition: all 0.5s ease 0s;
}
.item:hover .item, .item:hover span.icon{
background:#fff;
border-radius:10px;
-webkit-transition: all .5s ease 0;
transition: all .5s ease 0;
transition: all 0.5s ease 0s;
}
.item:hover h6, .item:hover p{
color:#fff;
-webkit-transition: all .5s ease 0;
transition: all .5s ease 0;
transition: all 0.5s ease 0s;
}
.item .icon {
font-size: 40px;
margin-bottom:25px;
color: #00b4eb;   
width: 90px;
height: 90px;
line-height: 96px;
border-radius: 50px;
}
.item .feature_box_col_one{
background:rgba(247, 198, 5, 0.20);
color:#00b4eb
}
.item .feature_box_col_two{
background:rgba(255, 77, 28, 0.15);
color:#00b4eb
}
.item .feature_box_col_three{
background:rgba(0, 147, 38, 0.15);
color:#00b4eb
}
.item .feature_box_col_four{
background:rgba(0, 108, 255, 0.15);
color:#00b4eb
}
.item .feature_box_col_five{
background:rgba(146, 39, 255, 0.15);
color:#00b4eb
}
.item .feature_box_col_six{
background:rgba(23, 39, 246, 0.15);
color:#00b4eb
}
.item p{
font-size:15px;
line-height:26px;
}
.item h6 {
margin-bottom:20px;
color:#2f2f2f;
}
.mission p {
margin-bottom: 10px;
font-size: 15px;
line-height: 28px;
font-weight: 500;
}
.mission i {
display: inline-block;
width: 50px;
height: 50px;
line-height: 50px;
text-align: center;
background: #00b4eb;
border-radius: 50%;
color: #fff;
font-size: 25px;
}
.mission .small-text {
margin-left: 10px;
font-size: 13px;
color: #666;
}
.skills {
padding-top:0px;
}
.skills .prog-item {
margin-bottom: 25px;
}
.skills .prog-item:last-child {
margin-bottom: 0;
}
.skills .prog-item p {
font-weight: 500;
font-size: 15px;
margin-bottom: 10px;
}
.skills .prog-item .skills-progress {
width: 100%;
height: 10px;
background: #e0e0e0;
border-radius:20px;
position: relative;
}
.skills .prog-item .skills-progress span {
position: absolute;
left: 0;
top: 0;
height: 100%;
background: #00b4eb;
width: 10%;
border-radius: 10px;
-webkit-transition: all 1s;
transition: all 1s;
}
.skills .prog-item .skills-progress span:after {
content: attr(data-value);
position: absolute;
top: -5px;
right: 0;
font-size: 10px;
font-weight:600;    
color: #fff;
background:rgba(0, 0, 0, 0.9);
padding: 3px 7px;
border-radius: 30px;
}